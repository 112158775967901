import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import ReservationCancelationConfirmation from '../ReservationCancelationConfirmation/ReservationCancelationConfirmation'; // eslint-disable-line
import Loader from '../../Loader/Loader';

require('./ReservationCard.scss');
const iconDownload = require('./icon-download.svg');
const iconDelete = require('./icon-delete.svg');


const MapPlayer = ({ player,pastReservation }) => ( //eslint-disable-line
  <span className={ClassNames({ 'player--cancelled': player.status === 'geannuleerd' })}>
    <br />
    {player.status !== 'geannuleerd' && !pastReservation && player.voucher_uri && (
      <>
        <strong>{`${player.first_name} ${player.prefix || ''} ${player.last_name}`}</strong>
        {player.email === localStorage.getItem('email') && ' (ik)'}
      </>
    )}
    {(player.status === 'geannuleerd' || pastReservation || !player.voucher_uri)
    && `${player.first_name} ${player.prefix || ''} ${player.last_name}
    ${player.email === localStorage.getItem('email') ? '(jij)' : ''}`
    }
  </span>);

const mapDate = (date) => {
  const dateObject = new Date(date);
  return `${(`0${dateObject.getDate()}`).substr(-2)}-${(`0${dateObject.getMonth() + 1}`).substr(-2)}-${dateObject.getFullYear()}`; // eslint-disable-line max-len
};

class ReservationCard extends Component {
  static propTypes = {
    reservation: PropTypes.objectOf(PropTypes.any).isRequired,
    onRefresh: PropTypes.func.isRequired,
    pastReservation: PropTypes.bool.isRequired,
  }

  constructor() {
    super();
    this.state = { data: [], cancelReservation: false, loading: false }; // eslint-disable-line
  }


  handleClose = () => {
    this.setState({ cancelReservation: '' });
  }

  handleCancel = async () => {
    const { onRefresh, reservation } = this.props;
    const { cancelReservation } = this.state;
    this.setState({ loading: true });
    const url = `/api/reservations/${reservation.reservation_id}${cancelReservation === 'group' ? '/group' : ''}/cancel`; // eslint-disable-line max-len
    await fetch(`${process.env.CONF_API_URL}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    this.setState({ cancelReservation: '' });
    onRefresh();
  }

  cancelReservation(e, type) {
    e.preventDefault();
    this.setState({ cancelReservation: type });
  }

  render() {
    const { reservation, pastReservation } = this.props;
    const { cancelReservation, loading } = this.state;
    const userReservationIsCancelled = reservation.players && !!reservation.players.find(
      player => player.email === localStorage.getItem('email') && player.status === 'geannuleerd',
    );

    return (
      <div className="reservationcard">
        <Row className="reservationcard__row">
          <Col xs={12} sm={8} className="reservationcard__col">
            <span className="reservationcard__date">{mapDate(reservation.play_date)}</span>
            <h1 className="reservationcard__title">
              {reservation.golfclub.name}
              <span className="reservationcard__title__city">{reservation.golfclub.city}</span>
            </h1>
          </Col>
          {(reservation.voucher_uri) && (
            <Col xsHidden sm={4} className="reservationcard__col reservationcard__col--right">
              {((!pastReservation)) && (
                <a
                  className="reservationcard__download"
                  href={`${process.env.CONF_API_URL}${reservation.voucher_uri}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={iconDownload} alt="download" />
                  {reservation.is_group_master ? 'download alle vouchers' : 'download voucher' }
                </a>
              )}
            </Col>
          )}

          {!reservation.voucher_uri && reservation.voucher_msg && (
          <Col xsHidden sm={4} className="reservationcard__col reservationcard__col--right">
            {((!pastReservation) || true) && (
            <span
              className="reservationcard__download reservationcard__download--disabled"
            >
              {reservation.voucher_msg}
            </span>
            )}
          </Col>
          )}

          {reservation.bonus_used && (
            <Col xs="12" className="reservationcard--light ml-0 pl-0 reservationcard__col">
              {`Bonusspeelrecht gebruikt: ${reservation.bonus_used.title}`}
            </Col>
          )}
        </Row>

        {reservation.playright_expired && (
        <Row className="reservationcard__row last">
          <Col xs={12} className="reservationcard__col">
            <p>

              <div className="notification notification--warning w-100">
              Deze reservering is te laat geannuleerd. Je speelrechten zijn hierdoor verloren gegaan.
              </div>
            </p>
          </Col>
        </Row>)}


        <Row className="reservationcard__row last">
          <Col xs={12} sm={8} className="reservationcard__col">
            <p>
              {!reservation.players
              && <i className="reservationcard--light">Geen medespelers voor deze reservering.</i>}

              {reservation.players
                && <strong>Groepsboeking:</strong>}

              {reservation.players
              && (reservation.players.map(player => <MapPlayer pastReservation={pastReservation} player={player} />)
              )}
            </p>
          </Col>

          <Col xs={12} sm={4} className="reservationcard__col reservationcard__col--right">
            {!pastReservation && (
            <p>
              {!userReservationIsCancelled
                && (
                <a
                  href="/reservations"
                  className="reservationcard__cancel reservationcard__cancel--own"
                  onClick={e => this.cancelReservation(e, 'individual')}
                >
                  <img src={iconDelete} alt="download" style={{ paddingRight: '3px', marginTop: '-3px' }} />
                  {reservation.players && <>
                  annuleer alleen mijn eigen reservering</>}

                  {!reservation.players && <>annuleer mijn reservering</>}
                </a>
                )}
              {reservation.is_group_master
              && (
              <a
                href="/reservations"
                className="reservationcard__cancel"
                onClick={e => this.cancelReservation(e, 'group')}
              >
                <img src={iconDelete} alt="download" style={{ paddingRight: '3px', marginTop: '-3px' }} />
                annuleer volledige groepsboeking
              </a>
              )
              }
            </p>
            )}
          </Col>

          <Col xs={12} smHidden mdHidden lgHidden>
            {!pastReservation && reservation.voucher_uri && (
            <a
              className="reservationcard__download reservationcard__download--link"
              href={`${process.env.CONF_API_URL}${reservation.voucher_uri}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {reservation.is_group_master ? 'download alle vouchers' : 'download voucher' }
            </a>
            )}
          </Col>

          {!reservation.voucher_uri && reservation.voucher_msg && (

          <Col xs={12} smHidden mdHidden lgHidden>
            {((!pastReservation) || true) && (
            <span
              className="reservationcard__download reservationcard__download--disabled"
            >
              {reservation.voucher_msg}
            </span>
            )}
          </Col>
          )}

          {reservation.golfclub.reservation_description && (new Date(reservation.play_date) > new Date()) && (
          <Col xs={12} className="reservationcard__reservation-info mt-5 pt-5">
            <p dangerouslySetInnerHTML={{ __html: reservation.golfclub.reservation_description }} />
          </Col>
          )}
        </Row>

        {loading && (
        <div className="reservationcard__loading">
          <Loader size={48} color="dark-grey" />
        </div>
        )}

        <ReservationCancelationConfirmation
          cancelationType={cancelReservation}
          players={reservation.players && reservation.players.length}
          active={!!cancelReservation}
          onCancel={this.handleCancel}
          onClose={this.handleClose}
          playrightExpired={reservation.playright_expired}
          isGroupMaster={reservation.is_group_master}
        />
      </div>
    );
  }
}

export default ReservationCard;
