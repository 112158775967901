import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import ReservationWizardButtons from '../../ReservationWizardButtons/ReservationWizardButtons';
import { ResetReservation } from '../../../redux/actions/ReservationActions';

require('./ReservationConfirmStep.scss');

class ReservationConfirmStep extends Component {
  constructor() {
    super();
    this.state = { loading: true, errorMsg: '' };
  }

  componentDidMount() {
    this.makeReservation();
  }

  makeReservation = async () => {
    const { reservation, resetReservation } = this.props;
    const body = {
      holes: reservation.holes,
      golfclub: reservation.golfclub ? reservation.golfclub.id : 0,
      players: reservation.players.map(player => player.email),
      date: reservation.date,
      windowSize: {
        browser: { width: window.innerWidth, height: window.innerHeight },
        device: { width: window.screen.width, height: window.screen.height },
      },
    };
    const data = await fetch(`${process.env.CONF_API_URL}/api/reservations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => { if (res.status === 401) { window.location = '/auth/login'; } return res.json(); });

    resetReservation();
    if (!data.success) {
      this.setState({ loading: false, errorMsg: data.error || 'fout bij het maken van de reservering.' });
      return;
    }
    this.setState({ loading: false, errorMsg: '' });
  }


  render() {
    const { loading, errorMsg } = this.state;
    return (
      <section className="wizardStep wizardStepBack">
        <Grid className=" wizardStep__section">
          <div className="wizardStep__section__wrapper">
            <h2>Reserveren</h2>
            <h1>Bevestiging</h1>

            {loading && <Loader className="dataLoader" size={36} />}
            {!loading && errorMsg && (
            <p>
              {`Er is een fout opgetreden: ${errorMsg}`}
            </p>
            )}

            {!loading && !errorMsg && (
            <p>
              <strong>Je reservering is succesvol geplaatst!</strong>
              <br />
              <br />
              <Link to="/reservations" className="view-reservations a--white">&raquo; Bekijk je reserveringen</Link>

            </p>
            )}

          </div>

          <ReservationWizardButtons
            backHidden={!loading && !errorMsg}
            backText="begin opnieuw"
            backPath="/reservation/players"
            nextPath="/reservations"
            nextText="bekijk reserveringen"
          />

        </Grid>
      </section>
    );
  }
}


ReservationConfirmStep.propTypes = {
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
  resetReservation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ reservation }) => ({ reservation });
const mapDispatchToProps = { resetReservation: ResetReservation };
export default connect(mapStateToProps, mapDispatchToProps)(ReservationConfirmStep);
