import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';

import './LoginPage.scss';
import '../ReservationWizardAutoComplete/ReservationWizardAutoComplete.scss';
import Loader from '../Loader/Loader';
import errorIcon from '../ReservationWizardAutoComplete/error-icon.svg';
import hideIcon from './hide.svg';
import viewIcon from './view.svg';
import { SetUserInfo } from '../../redux/actions/UserActions';


class LoginPage extends Component {
  static propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired, //eslint-disable-line
  }

  constructor() {
    super();
    this.state = {
      loginError: false, username: '', password: '', loading: false, showPassword: false,
    };
  }

  componentDidMount() {
    // setTimeout(() => { this.setState({ initialLoading: false }); }, 800);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.requestToken();
    this.setState({ loading: true });
  }

  requestToken = () => {
    const { username, password } = this.state;
    const payload = {
      grant_type: 'password',
      client_id: '1_45st3p3cvj6sok88cg044gkcc8g88c8kk0kc0go44skoo44c4o',
      client_secret: '4o8tivyhxgg0ok00k0844kkcoo4wsgwck4kcgc4000oww8804w',
      username,
      password,
    };

    fetch(`${process.env.CONF_API_URL}/api/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => this.handleTokenRequest(response));
  }

  handleTokenRequest = (response) => {
    this.setState({ loading: false, loginError: '' });

    if (response.status === 200) {
      this.handleUserLogin(response);
    } else if (response.status === 401) {
      this.setState({ loginError: 'Verkeerd e-mailadres of wachtwoord' });
    } else {
      this.setState({ loginError: 'onbekende fout' });
    }
  }

  handleUserLogin = async (response) => {
    const { username } = this.state;
    const data = await response.json();
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('email', username);
    await this.getUserInfo();
    window.location = '/reservations';
  }

  getUserInfo = async () => {
    const { setUserInfo } = this.props;
    const response = await fetch(`${process.env.CONF_API_URL}/api/users/me`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    const json = await response.json();
    await localStorage.setItem('has_subscription', !!json.playrights.has_subscription);
    setUserInfo(json);
  }

  toggleViewPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  render() {
    // if (initialLoading) return (<Loader className="wizardLoader" size={36} />);
    const { loginError, loading, showPassword } = this.state;
    return (
      <section className="wizardStep wizardStepBack">

        <Grid className=" wizardStep__section">
          <div className="wizardStep__section__wrapper">
            <h2>Authenticatie</h2>
            <h1>Login om door te gaan</h1>
            <Row>
              <Col xs={12} sm={6} smOffset={3}>
                <form onSubmit={this.onSubmit}>
                  {!loading && loginError
                        && (
                        <div className="alert alert-danger">
                          <img src={errorIcon} alt="" />
                          <span style={{ marginLeft: '10px' }}>{loginError}</span>
                        </div>
                        )}
                  <div className="wizard-autocomplete">
                    <input
                      className="wizard-autocomplete-input"
                      type="email"
                      placeholder="e-mailadres"
                      onChange={(e) => { this.setState({ username: e.target.value }); }}
                    />
                    <div className="wizard-autocomplete-modifier">
                      {loading && <Loader size={24} color="light-grey" /> }
                    </div>
                  </div>

                  <div className="wizard-autocomplete">
                    <input
                      className="wizard-autocomplete-input"
                      type={showPassword ? 'input' : 'password'}
                      placeholder="wachtwoord"
                      onChange={(e) => { this.setState({ password: e.target.value }); }}
                    />
                    <div className="wizard-autocomplete-modifier">
                      <button onClick={this.toggleViewPassword} type="button" className="viewPassword">
                        <img src={showPassword ? hideIcon : viewIcon} alt="hide" />
                      </button>
                    </div>

                  </div>

                  <button
                    type="submit"
                    className="reservationWizard__buttons__button"
                    onSubmit={this.onSubmit}
                  >
                  inloggen
                  </button>
                  <Link to="/auth/forgot" style={{ marginLeft: 20 }} className="a--white">wachtwoord vergeten</Link>
                </form>
              </Col>
            </Row>
          </div>
        </Grid>
      </section>
    );
  }
}


const mapStateToProps = ({ reservation }) => ({ reservation });
const mapDispatchToProps = { setUserInfo: SetUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
