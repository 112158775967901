import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';

import classNames from 'classnames';
import { FetchData } from '../../redux/actions/ServerDataActions';
import { AddPlayer, SetPartnerType } from '../../redux/actions/ReservationActions';
import './ReservationWizardAutoComplete.scss';
import Loader from '../Loader/Loader';
import addIcon from './add-icon.svg';
import errorIcon from './error-icon.svg';

import mockData from './data.json';

class ReservationWizardAutoComplete extends Component {
  static propTypes = {
    reservation: PropTypes.objectOf(PropTypes.any).isRequired,
    serverData: PropTypes.objectOf(PropTypes.any).isRequired,
    addPlayer: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    this.state = {
      loading: false, error: null, filterData: mockData, reservationBlocked: false,
    };
    this.inputRef = React.createRef();
    this.submitRef = React.createRef();
  }

  componentDidMount() {
    const { fetchData } = this.props;
    fetchData('historicPlayers', '/api/reservations/players/historic');

    if (!this.isUserAdded(localStorage.email)) {
      this.addCurrentUser();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.addUser(this.inputRef.current.value);
  }

  onChange = (e) => {
    const needle = e.target.value;
    this.setRecommendationsView(true);
    const { reservation: {players} } = this.props; // eslint-disable-line
    this.filterRecommendations(needle);
  }

  addUser = (email) => {
    const { addPlayer } = this.props;
    this.setState({ loading: true, error: null });
    document.activeElement.blur();
    if (this.isUserAdded(email)) {
      this.setState({
        error: 'speler is al toegevoegd', loading: false, recommendations: false,
      });
    } else {
      fetch(`${process.env.CONF_API_URL}/api/reservations/players/check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify({ email }),
      })
        .then((res) => { if (res.status === 401) { window.location = '/auth/login'; } return res.json(); })
        .then((data) => {
          if (data.success && data.found && data.playrights.reservable) {
            addPlayer(data);
            this.setState({ loading: false, recommendations: false, filterData: mockData });
          } else if (data.found && !data.playrights.reservable) {
            this.setState({
              loading: false,
              recommendations: false,
              error: `${data.first_name} ${data.playrights.reason}`,
            });
          } else {
            this.setState({ loading: false, recommendations: false, error: `${email} niet gevonden` });
          }
        });
      this.inputRef.current.value = '';
      document.activeElement.blur();
    }
  }

  clickRecommendation = (email) => {
    this.inputRef.current.value = email;
    this.addUser(email);
  }

  setRecommendationsView = (status) => {
    this.setState({ recommendations: status });
  }

  addCurrentUser() {
    fetch(`${process.env.CONF_API_URL}/api/users/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((res) => { if (res.status === 401) { window.location = '/auth/login'; } return res.json(); })
      .then((data) => {
        if (data.success && data.playrights.reservable && data.isProfileComplete) {
          this.addUser(data.email);
          this.setState({
            loading: false, recommendations: false, reservationBlocked: false,
          });
        } else if (data.success && !data.isProfileComplete) {
          this.setState({
            loading: false,
            recommendations: false,
            error: 'Je profiel is niet compleet. <a href="/profile">Maak uw profiel compleet</a> voordat je verder gaat met reserveren. ', //eslint-disable-line
            reservationBlocked: true,
          });
        } else if (data.success && !data.playrights.reservable) {
          this.setState({
            loading: false,
            recommendations: false,
            error: data.playrights.reason.replace('Heeft', 'je hebt'),
            reservationBlocked: true,
          });
        }
      });
  }

  filterRecommendations(needle) {
    const { serverData: { historicPlayers } } = this.props;
    const data = (historicPlayers && historicPlayers.data) || null;
    let filterData = {};
    if (data) {
      filterData = data.filter((player) => {
        const fullname = `${player.first_name} ${player.prefix} ${player.last_name}`;
        const filter1 = fullname.toLowerCase().indexOf(needle.toLowerCase()) > -1;
        const filter2 = player.email && player.email.toLowerCase().indexOf(needle.toLowerCase()) > -1;
        const filter3 = !this.isUserAdded(player.email);
        return (filter1 || filter2) && filter3;
      });
    } else {
      filterData = data;
    }
    this.setState({ error: null, filterData });
  }

  isUserAdded(email) {
    const { reservation: { players } } = this.props;
    return !!players.find(player => player.email === email);
  }

  renderRecommendation= ({
    first_name, prefix, last_name, email, playrights, // eslint-disable-line camelcase
  }, index) => (
    <div
      key={index}
      className={classNames(
        'wizard-autocomplete-recommendation',
        {
          'wizard-autocomplete-recommendation--disabled': playrights && !playrights.reservable,
        },
      )}
      onClick={() => { this.clickRecommendation(email); }} // eslint-disable-line
    >
      <span className="wizard-autocomplete-recommendation__name">
        {`${first_name || ''} ${prefix || ''} ${last_name || ''}` /* eslint-disable-line camelcase */ }
      </span>

      {playrights && !playrights.reservable // eslint-disable-line
        && <span className="wizard-autocomplete-recommendation__message">{playrights.reason}</span>
      }
    </div>
  )

  render() {
    // const { reservation } = this.props;
    const {
      loading,
      error,
      filterData,
      recommendations,
      reservationBlocked,
    } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        {!reservationBlocked && (
        <Col xs={12} sm={6}>

          <div className="wizard-autocomplete">
            <input
              className="wizard-autocomplete-input"
              type="email"
              placeholder="e-mailadres"
              onChange={this.onChange}
              onBlur={() => { setTimeout(() => this.setRecommendationsView(false), 10); }}
              onFocus={(e) => { this.setRecommendationsView(true); this.onChange(e); }}
              ref={this.inputRef}
            />

            <div className="wizard-autocomplete-modifier">
              {loading && !error && <Loader size={24} color="light-grey" /> }
              {/* {error && <>
                <img src={errorIcon} alt="" />
              </>} */}
              {!loading && !error && this.inputRef.current && this.inputRef.current.value !== ''
          && (
          <button ref={this.submitRef} className="wizard-autocomplete-add-btn" type="submit">
            <img src={addIcon} alt="" />
          </button>
          )}
            </div>
            {filterData
            && (
            <div className={classNames('wizard-autocomplete-recommendation-wrapper', { active: recommendations })}>
              {filterData.map((user, index) => this.renderRecommendation(user, index))}
            </div>
            )}
          </div>
        </Col>
        )}
        <Col xs={12} sm={10}>
          <div className={classNames('wizard-autocomplete-warning', { active: error })}>
            <img src={errorIcon} alt="" />
            {reservationBlocked && <strong>Kan geen reservering starten:</strong>}
            {!reservationBlocked && <strong>Speler kan niet worden toegevoegd:</strong>}
            <span className="wizard-autocomplete-warning-message" dangerouslySetInnerHTML={{ __html: error }} />
          </div>
        </Col>
      </form>
    );
  }
}

const mapStateToProps = ({ reservation, serverData }) => ({ reservation, serverData });
const mapDispatchToProps = { addPlayer: AddPlayer, setPartnerType: SetPartnerType, fetchData: FetchData };
export default connect(mapStateToProps, mapDispatchToProps)(ReservationWizardAutoComplete);
