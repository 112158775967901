import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Grid, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { RemovePlayer } from '../../redux/actions/ReservationActions';
import './ReservationWizardPlayersOverview.scss';
import removeIcon from './icon-delete.svg';


class ReservationWizardPlayer extends Component {
    static propTypes = {
      player: PropTypes.objectOf(PropTypes.any).isRequired,
      removePlayer: PropTypes.func.isRequired,
    }

    constructor() {
      super();
      this.state = { visible: false };
    }

    componentDidMount() {
      setTimeout(() => {
        this.setState({ visible: true });
      }, 100);
    }

    render() {
      const { player, removePlayer } = this.props;
      const { visible } = this.state;
      const { email: currentUserEmail } = localStorage;

      return (
        <div className={classNames('players-overview-player', {
          'players-overview-player-visible': visible,
          'players-overview-player-me': player.email === currentUserEmail,
        })}
        >
          <span className="players-overview-player--name-first">{player.first_name}</span>
          <span className="players-overview-player--name-last">
            {player.prefix}
            {' '}
            {player.last_name}
          </span>

          <span className="players-overview-player--info">
            <strong>{player.playrights.this_month.max_holes}</strong>
            {' '}
            holes
          </span>

          {player.email !== currentUserEmail && (
          <button
            type="button"
            className="players-overview-player--remove-btn"
            onClick={() => { removePlayer(player); }}
          >
            <img src={removeIcon} alt="remove" />
          </button>
          )}
        </div>);
    }
}

// eslint-disable-next-line
class ReservationWizardPlayersOverview extends Component {
  static propTypes = {
    removePlayer: PropTypes.func.isRequired,
    reservation: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  removePlayer = (player) => {
    const { removePlayer } = this.props;
    removePlayer(player);
  }

  render() {
    const { reservation: { players } } = this.props;
    return (
      <div className="players-overview">
        {players.map(player => (
          <ReservationWizardPlayer
            key={player.email}
            player={player}
            removePlayer={this.removePlayer}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ reservation }) => ({ reservation });
const mapDispatchToProps = { removePlayer: RemovePlayer };
export default connect(mapStateToProps, mapDispatchToProps)(ReservationWizardPlayersOverview);
