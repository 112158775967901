import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import ReservationCard from '../ReservationCard/ReservationCard';
import icon from './icon-close-black.svg';

require('./ReservationOverview.scss');


class ReservationOverview extends Component {
  constructor() {
    super();
    this.state = { loading: true, loadingNews: true, errorMsg: '', reservations: [], historicReservations: [], news: [] }; // eslint-disable-line
  }

  componentDidMount() {
    this.fetchReservations();
    this.fetchImportantNews();
  }

  fetchReservations = async () => {
    this.setState({ loading: true });

    const data = await fetch(`${process.env.CONF_API_URL}/api/reservations`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((res) => { if (res.status === 401) { window.location = '/auth/login'; } return res.json(); });
    if (!data.success) {
      this.setState({ loading: false, errorMsg: data.error || 'fout bij het ophalen van reserveringen' });
    } else {
      this.setState({ loading: false, reservations: data.data });
    }
  }

   fetchImportantNews = async () => {
     this.setState({ loadingNews: true });

     const data = await fetch(`${process.env.CONF_API_URL}/api/news/important`, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${localStorage.getItem('access_token')}`,
       },
     })
       .then((res) => { if (res.status === 401) { window.location = '/auth/login'; } return res.json(); });
     if (!data.success) {
       this.setState({ loadingNews: false });
     } else {
       this.setState({ loadingNews: false, news: data.data });
     }
   }

   dismissImportantNews = () => {
     this.setState({ news: [] });
     fetch(`${process.env.CONF_API_URL}/api/news/important/dismiss`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${localStorage.getItem('access_token')}`,
       },
     });
   }

   render() {
    const { loading, reservations, historicReservations, errorMsg, loadingNews, news } = this.state; // eslint-disable-line
     return (
       <section className="wizardStep wizardStep--withMenu">
         <Grid className="wizardStep__section">
           {(!loadingNews && !!news.length) && (<>
             <h2 className="mb-2">Belangrijk nieuws</h2>
             <div className="wizardStep__section__wrapper">
               {/* {loadingNews && <Loader className="dataLoader" size={36} />} */}

               {!loadingNews && news.map(newsItem => (
                 <div className="container p-4 important-news">
                   <button onClick={this.dismissImportantNews} className="important-news__dismiss" type="button">
                     <img src={icon} alt="x" />
                   </button>


                   <Col xs={12}>
                     <h3>{newsItem.title}</h3>
                     <p dangerouslySetInnerHTML={{ __html: newsItem.contents }} />
                     {/* {JSON.stringify(newsItem)} */}
                   </Col>
                 </div>
               ))}

             </div>
           </>
           )}

           <h2 className="mb-2">Mijn reserveringen</h2>

           <div className="wizardStep__section__wrapper">
             {loading && <Loader className="dataLoader" size={36} />}

             {!loading && !reservations.length && !errorMsg
            && (
            <p>
              Er zijn geen reserveringen gevonden!
            </p>
            )}
             {!loading && errorMsg && (
             <p>
               {`<strong>Fout bij het ophalen van reserveringen:</strong> ${errorMsg}`}
             </p>
             )}

             {!loading && reservations.map(reservation => (
               <ReservationCard
                 reservation={reservation}
                 key={reservation.reservation_id}
                 onRefresh={this.fetchReservations}
               />))}

           </div>

           <Row>
             <Col xs={12} className="text-right">
               <NavLink
                 activeClassName="active"
                 to="/reservations/history"
                 className="btn btn--ghost mt-2"
               >
Speelgeschiedenis
               </NavLink>
                &nbsp; &nbsp;
               <NavLink to="/reservation/players" className="btn mt-2">Plaats een nieuwe reservering</NavLink>
             </Col>
           </Row>

         </Grid>
       </section>
     );
   }
}

export default ReservationOverview;
