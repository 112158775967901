import React, { Component } from 'react';
import { Col, Grid } from 'react-bootstrap';
import { withRouter, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import CourseInfo from './components/CourseInfo/CourseInfo/CourseInfo';
import ReservationWizard from './components/ReservationWizard/ReservationWizard';
import Header from './components/Header/Header';
import LoginPage from './components/LoginPage/LoginPage';
import LogoutPage from './components/LogoutPage/LogoutPage';
import ReservationOverview from './components/Reservations/ReservationOverview/ReservationOverview';
import Profile from './components/Profile/Profile';
import SubscriptionOverview from './components/Subscriptions/SubscriptionOverview/SubscriptionOverview';
import NewsOverview from './components/News/NewsOverview/NewsOverview';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import StaticPage from './components/StaticPage/StaticPage';
import Impersonate from './components/LoginPage/Impersonate';
import HistoricReservationOverview from './components/Reservations/ReservationOverview/HistoricReservationOverview';
import FaqList from './components/Faq/FaqList/FaqList';


class AppRoutes extends Component {
  static propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor() {
    super();
    this.state = { fullPage: false };
  }

  componentDidMount() {
    this.setFullPage();
  }

  componentDidUpdate(newState) {
    const { location } = this.props;
    if (newState.location !== location) {
      this.setFullPage();
    }
  }

  setFullPage = () => {
    const { location } = this.props;
    const fullPage = location.pathname.indexOf('/reservation') === 0
     || location.pathname.indexOf('/auth') === 0
     || location.pathname.indexOf('/subscriptions') === 0;
    this.setState({ fullPage });
  }


  render() {
    const { fullPage } = this.state;
    const { location } = this.props;

    return (
      <div>
        <Header fullPage={fullPage
        && (location.pathname.indexOf('/reservations') === -1
        && location.pathname.indexOf('/subscriptions') === -1)}
        />
        { !fullPage && (
        <Grid>
          <Col xs={12}>
            <Route
              exact
              path="/"
              render={() => (
                localStorage.access_token ? (
                  <Redirect to="/reservations" />
                ) : (
                  <Redirect to="/auth/login" />
                )
              )}
            />
            <Route path="/info" component={CourseInfo} on />
            <Route path="/profile" component={Profile} on />
            <Route
              path="/partnerinformatie"
              render={() => (
                <StaticPage page="partnerinformatie" />
              )}
            />
            <Route path="/news" component={NewsOverview} on />
            <Route path="/courses" component={CourseInfo} on />
            <Route path="/faq" component={FaqList} on />
          </Col>
        </Grid>
        ) }
        <Route path="/reservation" component={ReservationWizard} />
        <Route path="/reservations" exact component={ReservationOverview} />
        <Route path="/reservations/history" component={HistoricReservationOverview} />
        <Route path="/subscriptions" component={SubscriptionOverview} />
        <Route path="/auth/login" component={LoginPage} on />
        <Route path="/auth/forgot" component={ForgotPassword} on />
        <Route path="/auth/logout" component={LogoutPage} on />
        <Route path="/auth/impersonate" component={Impersonate} on />
      </div>
    );
  }
}

export default withRouter(AppRoutes);
