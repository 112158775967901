import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
// import { Grid } from 'react-bootstrap';
// import Loader from '../../Loader/Loader';

require('./ReservationCancelationConfirmation.scss');


class ReservationCancelationConfirmation extends Component {
  static propTypes = {
    cancelationType: PropTypes.string.isRequired,
    active: PropTypes.bool,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    playrightExpired: PropTypes.bool,
    isGroupMaster: PropTypes.bool.isRequired,
    players: PropTypes.number.isRequired,
  }

  static defaultProps = {
    active: false,
    onCancel: () => {},
    onClose: () => {},
    playrightExpired: false,
  }

  constructor() {
    super();
    this.state = { reservations: [] }; // eslint-disable-line
  }

  onClose = (e) => {
    e.preventDefault();
    const { onClose } = this.props;
    onClose();
  }

  onCancel = (e) => {
    e.preventDefault();
    const { onCancel } = this.props;
    onCancel();
  }


  render() {
    const { loading, reservations, errorMsg } = this.state; // eslint-disable-line
    const {
      cancelationType, active, playrightExpired, players, isGroupMaster,
    } = this.props;

    return (
      <section className={ClassNames('confirmation', { active })}>
        <div className="confirmation__container">
          <h1>Reservering annuleren</h1>

          <p>

            {cancelationType === 'individual' && players > 1 && (
                <>
                  weet je zeker dat je alleen je eigen reservering definitief wilt verwijderen?
                  <br />
                  <span className="confirmation__container--alert">
                    <strong>Let Op!</strong>
                    {' '}
                    {isGroupMaster
                      // eslint-disable-next-line max-len
                      ? 'de reserveringen van je medespelers blijven staan als je alleen je eigen reservering verwijdert.'
                      : 'Je kan alleen je eigen reservering annuleren. De hoofdboeker kan de hele groep annuleren.'
                    }
                    <br />
                  </span>
                </>
            )}

            {!players && (
                <>
                  weet je zeker dat je deze reservering definitief wilt verwijderen?
                </>
            )}

            {cancelationType === 'group' && (
              <>
                weet je zeker dat je de volledige groepsboeking definitief wilt verwijderen?
                <br />
                <span className="confirmation__container--alert">
                  <strong>Let op!</strong>
                  {' '}
              de reserveringen van al je medespelers worden ook verwijderd!
                </span>
              </>
            )}
          </p>

          {playrightExpired
          && (<>
            <br />
            <div className="reservationcard__reservation-info">
            Let op! Omdat je op de speeldatum annuleert vervalt je speelrecht.
            </div><br />
        </>

          )}

          <div className="confirmation__container__buttons">
            <button
              className="confirmation__container__buttons__button"
              type="button"
              onClick={this.onClose}
            >
            nee, behouden
            </button>
            <button
              className="confirmation__container__buttons__button confirmation__container__buttons__button--danger"
              type="button"
              onClick={this.onCancel}
            >
              ja, annuleren
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default ReservationCancelationConfirmation;
